import { template as template_b5d12cb695884d9980d771dde6a6b5c2 } from "@ember/template-compiler";
const FKText = template_b5d12cb695884d9980d771dde6a6b5c2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
