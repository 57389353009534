import { template as template_35ee940a15ab4d4a9e553b2caba02c71 } from "@ember/template-compiler";
const FKControlMenuContainer = template_35ee940a15ab4d4a9e553b2caba02c71(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
